import PopupPageComponent from '@wix/thunderbolt-elements/src/components/PopupPage/viewer/PopupPage';


const PopupPage = {
  component: PopupPageComponent
};


export const components = {
  ['PopupPage']: PopupPage
};


// temporary export
export const version = "1.0.0"
